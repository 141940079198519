import { ResetService } from "@/decorators/resetService.decorator";
import { pusherEvents } from "@/enums/pusherEvents";
import { TaxCategory, TaxCategoryDefault } from "@/interfaces/taxCategory";
import { TaxCategoryTableMetadata } from "@/metadata/taxCategory";
import { taxCategoryActionService } from "@/services/taxCategory.action.service";
import { taxCategoryService } from "@/services/taxCategory.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionsSubheaderComponent,
  ActionSubheader,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./TaxCategoryList.template.vue";

const namespace = "TaxCategoryModule";

@Component({
  mixins: [Template],
  components: {
    ActionsSubheaderComponent,
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(taxCategoryService)
export default class TaxCategoryListComponent extends Vue {
  @Getter("taxCategories", { namespace: "TaxCategoryModule" })
  public taxCategories!: TaxCategory[] | null;

  @Action("loadTaxCategories", { namespace: "TaxCategoryModule" })
  public loadTaxCategoriesAction!: Callback;

  @Getter("loading", { namespace })
  protected loading!: boolean;

  @Getter("pagination", { namespace })
  protected pagination!: TablePagination;

  @Action("setPageNav", { namespace: "PageNavModule" })
  protected setPageNav!: PageNavAction;

  protected model: TaxCategory = cloneDeep(TaxCategoryDefault);
  protected rowActions: TableAction[] = taxCategoryActionService.getRowActions();
  protected generalActions: ActionSubheader[] = taxCategoryActionService.getGeneralActions();
  protected headers: TableHeader[] = TaxCategoryTableMetadata;

  public filter(header: TableHeader) {
    taxCategoryService.sortQuery(header);
  }

  public changePagination(pagination: TablePagination) {
    const paginate = taxCategoryService.paginationAction();
    paginate(pagination);
  }

  public back() {
    this.$router.push("/inventory/pricing");
  }

  protected mounted() {
    this.setPageNav({
      title: "tax_categories",
      rightActions: {
        generalActions: () => this.generalActions
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-chevron-left",
              action: this.back
            }
          ]
        }
      }
    });
    this.$changes.watch(
      pusherEvents.taxCategoryTouched,
      this.loadTaxCategoriesAction
    );
    this.loadTaxCategoriesAction();
  }
}
