import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const TaxCategoryTableMetadata: TableHeader[] = [
  {
    value: "name",
    label: i18n.t("inventory.tax_category").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "overall_rate",
    label: i18n.t("inventory.overall_rate").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];
