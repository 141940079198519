export interface TaxCategory {
  id: number | null;
  name: string;
  rate: number | null;
  taxes?: TaxBreak[] | null;
  account_id: number | null;
  created_at?: string | null;
  deleted_at?: string | null;
  updated_at?: string | null;
}

export interface TaxBreak {
  temporalId?: number;
  id?: number;
  name?: string;
  type?: string;
  // string type for table, number for manipulation and backend processing
  rate?: string | number;
  isNew?: boolean;
}

export const TaxCategoryDefault: TaxCategory = {
  id: null,
  name: "",
  rate: null,
  taxes: [],
  account_id: null
};
