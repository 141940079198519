import { policyList } from "@/enums/permissions";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import {
  ActionSubheader,
  TableAction,
  TableSuccessModalResponse
} from "helix-vue-components";
import { taxCategoryService } from "./taxCategory.service";

class TaxCategoryActionService {
  public getGeneralActions(): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        id: "btn_create_taxcat",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: "tax-category-add"
          });
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createTaxCategories
          )
      },
      {
        icon: "fal fa-search",
        id: "btn_search_taxcat",
        otherComponent: {
          name: "TableSearchComponent",
          action: taxCategoryService.searchEvent()
        }
      }
    ];
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: store.getters["PermissionsModule/hasPermission"](
          policyList.modifyTaxCategories
        )
          ? "fal fa-pen"
          : "fal fa-eye",
        id: "btn_edit_taxcat",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: store.getters["PermissionsModule/hasPermission"](
              policyList.modifyTaxCategories
            )
              ? "tax-category-edit"
              : "tax-category-view",
            params: {
              id: arg[0].id,
              currentModel: arg[0]
            }
          });
        }
      },
      {
        icon: "fal fa-trash-alt",
        id: "btn_delete_taxcat",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("tax_category_delete_confirm").toString(),
          modalSuccessText: "yes",
          modalIdSuccess: "btn_delete_yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            store
              .dispatch("TaxCategoryModule/deleteTaxCategory", arg.item)
              .finally(arg.unselectModal);
          },
          modalCancelText: "No",
          modalIdCancel: "btn_delete_no"
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.deleteTaxCategories
          )
      }
    ];
  }
}

export const taxCategoryActionService = new TaxCategoryActionService();
